import { CategoryLandingPage } from '@lambda/apis/src/category/types';

const defaultCategories: CategoryLandingPage = {
  baby: {
    pk: '05m61287c2d2',
    config: {
      title: 'Baby & Toys',
      tagline: 'Certified Preloved and New Baby Products & Toys',
    },
    storeConfig: {
      'reebelo-au': {
        tagline: 'Certified Preloved and New Baby Products & Toys',
      },
      'reebelo-us': {
        tagline: 'Certified Preloved & Used Baby Products & Toys',
      },
    },
    subCategories: [
      {
        pk: '05m612h3r6m1',
        config: {
          title: 'Baby Care',
          description: '',
        },
      },
      {
        pk: '05w1e4r0f0s5',
        config: {
          title: 'Baby Carriers',
          description: '',
        },
      },
      {
        pk: '05m615r0n4u4',
        config: {
          title: "Baby's Fashion",
          description: '',
        },
      },
      {
        pk: '05m616c0j661',
        config: {
          title: 'Bedding',
          description: '',
        },
      },
      {
        pk: '05q6d7g4f001',
        config: {
          title: 'Toys',
          description: '',
        },
      },
      {
        pk: '05m61721c264',
        config: {
          title: 'Changing',
          description: '',
        },
      },
      {
        pk: '05m6341595w7',
        config: {
          title: 'Child Safety Car Seats & Accessories',
          description: '',
        },
      },
      {
        pk: '05m636q102g2',
        config: {
          title: 'Comforters',
          description: '',
        },
      },
      {
        pk: '05m636v6j2e6',
        config: {
          title: 'Cots',
          description: '',
        },
      },
      {
        pk: '05m6t1d52616',
        config: {
          title: 'Feeding',
          description: '',
        },
      },
      {
        pk: '05m6t2h6f2t5',
        config: {
          title: 'Nursery Furniture, Bedding & Décor',
          description: '',
        },
      },
      {
        pk: '05w1e3m350p7',
        config: {
          title: 'Prams',
          description: '',
        },
      },
      {
        pk: '05m6t537q2m5',
        config: {
          title: 'Pregnancy & Post Partum Care',
          description: '',
        },
      },
    ],
  },
  electronics: {
    pk: '02q0v5s710k7',
    config: {
      title: 'Electronics',
      tagline: 'Certified Refurbished & Used Electronics',
    },
    subCategories: [
      {
        pk: '02h610t3d104',
        config: {
          title: 'Smartphones',
          description: '',
        },
      },
      {
        pk: '05t394s0h4e1',
        config: {
          title: 'Laptops',
          description: '',
        },
      },
      {
        pk: '05t3a2p3p2b0',
        config: {
          title: 'Tablets',
          description: '',
        },
      },
      {
        pk: '02h610t3d120',
        config: {
          title: 'Smartwatches',
          description: '',
        },
      },
      {
        pk: '03n2q486w473',
        config: {
          title: 'Computers',
          description: '',
        },
      },
      {
        pk: '02h610t3d250',
        config: {
          title: 'Audio',
          description: '',
        },
      },
      {
        pk: '05t36596t4f0',
        config: {
          title: 'Cameras & Drones',
          description: '',
        },
      },
      {
        pk: '03m6a0m45164',
        config: {
          title: 'Home Audio',
          description: '',
        },
      },
      {
        pk: '05t3f4c7n1e0',
        config: {
          title: 'Video',
          description: '',
        },
      },
      {
        pk: '05v7q4k2h2x5',
        config: {
          title: 'Printers',
          description: '',
        },
      },
      {
        pk: '05v7r2703255',
        config: {
          title: 'Televisions',
          description: '',
        },
      },
      {
        pk: '069083h176h6',
        config: {
          title: 'Accessories & Bundles',
          description: '',
        },
      },
    ],
  },
  gaming: {
    pk: '05m6w134l0u2',
    config: {
      title: 'Gaming',
      tagline: 'Certified Refurbished & Used Gaming',
    },
    subCategories: [
      {
        pk: '05m6w341v3c5',
        config: {
          title: 'Gaming Consoles',
          description: '',
        },
      },
      {
        pk: '05m6w29620f0',
        config: {
          title: 'PC Gaming',
          description: '',
        },
      },
      {
        pk: '05m6x743m094',
        config: {
          title: 'Video Games',
          description: '',
        },
      },
      {
        pk: '05m6x2d231b1',
        config: {
          title: 'Gaming Accessories',
          description: '',
        },
      },
    ],
  },
  'home-kitchen': {
    pk: '02n5t7v7l3p4',
    config: {
      title: 'home-kitchen',
      tagline: 'Certified Refurbished & Used Home & Kitchen',
    },
    subCategories: [
      {
        pk: '03n2k0n791e5',
        config: {
          title: 'Bath Products',
          description: '',
        },
      },
      {
        pk: '05m616c0j661',
        config: {
          title: 'Bedding',
          description: '',
        },
      },
      {
        pk: '02h610t3d261',
        config: {
          title: 'Furniture',
          description: '',
        },
      },
      {
        pk: '03n2k2t7d4u6',
        config: {
          title: 'Heating, Cooling & Air Quality',
          description: '',
        },
      },
      {
        pk: '02h610t3d132',
        config: {
          title: 'Home Appliances',
          description: '',
        },
      },
      {
        pk: '03n2k397n3h2',
        config: {
          title: 'Home Décor Products',
          description: '',
        },
      },
      {
        pk: '05r53217g012',
        config: {
          title: 'Kitchen Tools & Utensils',
          description: '',
        },
      },
      {
        pk: '05r53742w0h4',
        config: {
          title: 'Tableware',
          description: '',
        },
      },
      {
        pk: '02k5a2x2h105',
        config: {
          title: 'Storage & Organisation',
          description: '',
        },
      },
      {
        pk: '02n5v5d7a323',
        config: {
          title: 'Vacuum Cleaners & Floor Care',
          description: '',
        },
      },
      {
        pk: '02h610t3d307',
        config: {
          title: 'Doors & Door Accessories',
          description: '',
        },
      },
      {
        pk: '05r524s1f547',
        config: {
          title: 'Ironing',
          description: '',
        },
      },
    ],
  },
  'pet-supplies': {
    pk: '05h0u7u2t3j7',
    config: {
      title: 'Pet Supplies',
      tagline: 'Certified Refurbished & Used Pet Supplies',
    },
    subCategories: [
      {
        pk: '05m4n24514h5',
        config: {
          title: 'Cats',
          description: '',
        },
      },
      {
        pk: '05m4n2a6f1t2',
        config: {
          title: 'Dogs',
          description: '',
        },
      },
      {
        pk: '05m4n2f27063',
        config: {
          title: 'Horses',
          description: '',
        },
      },
      {
        pk: '05h0v076k2j6',
        config: {
          title: 'Pet Accessories',
          description: '',
        },
      },
      {
        pk: '05h0v0l476u7',
        config: {
          title: 'Pet Grooming Supplies',
          description: '',
        },
      },
    ],
  },
  'sports-fitness': {
    pk: '02h610t3d134',
    config: {
      title: 'Sports & Fitness',
      tagline: 'Certified Refurbished & Used Sports & Fitness',
    },
    subCategories: [
      {
        pk: '05w1f065u4t1',
        config: {
          title: 'Bikes',
          description: '',
        },
      },
      {
        pk: '05n7v6g6r4d2',
        config: {
          title: 'Binoculars',
          description: '',
        },
      },
      {
        pk: '02h610t3d232',
        config: {
          title: 'Camping & Hiking',
          description: '',
        },
      },
      {
        pk: '05w1f145j4v5',
        config: {
          title: 'E-scooters',
          description: '',
        },
      },
      {
        pk: '02h610t3d326',
        config: {
          title: 'E-skateboards',
          description: '',
        },
      },

      {
        pk: '05w1f24003g7',
        config: {
          title: 'Electric Bikes',
          description: '',
        },
      },
      {
        pk: '02h610t3d243',
        config: {
          title: 'Fitness',
          description: '',
        },
      },
      {
        pk: '02h610t3d110',
        config: {
          title: 'Golf',
          description: '',
        },
      },
      {
        pk: '02h610t3d265',
        config: {
          title: 'Outdoor Recreation',
          description: '',
        },
      },
      {
        pk: '02h610t3d413',
        config: {
          title: 'Scooters',
          description: '',
        },
      },
      {
        pk: '02h610t3d320',
        config: {
          title: 'Hoverboards',
          description: '',
        },
      },
      {
        pk: '0345l7f5u3c2',
        config: {
          title: 'Skiing',
          description: '',
        },
      },
    ],
  },
  'tools-home-improvement': {
    pk: '02n5r6d70612',
    config: {
      title: 'Tools & Home Improvement',
      tagline: 'Certified Refurbished & Used Tools & Home Improvement',
    },
    subCategories: [
      {
        pk: '02h610t3f430',
        config: {
          title: 'Air Tools & Air Compressors',
          description: '',
        },
      },
      {
        pk: '02h610t3d407',
        config: {
          title: 'Compressors',
          description: '',
        },
      },
      {
        pk: '02q17551m4g7',
        config: {
          title: 'Electrical Equipment',
          description: '',
        },
      },
      {
        pk: '02h610t3f402',
        config: {
          title: 'Grinders',
          description: '',
        },
      },
      {
        pk: '03j0m4m313u6',
        config: {
          title: 'Hardware',
          description: '',
        },
      },
      {
        pk: '02n5t0r5e191',
        config: {
          title: 'Patio, Lawn & Garden',
          description: '',
        },
      },
      {
        pk: '02h610t3f410',
        config: {
          title: 'Plumbing',
          description: '',
        },
      },
      {
        pk: '02h610t3d393',
        config: {
          title: 'Power Tools',
          description: '',
        },
      },
      {
        pk: '02q175n484c1',
        config: {
          title: 'Safety & Security',
          description: '',
        },
      },
      {
        pk: '02k5a2x2h105',
        config: {
          title: 'Storage & Organisation',
          description: '',
        },
      },
    ],
  },
};

export default defaultCategories;
