import { Swiper, SwiperSlide } from 'swiper/react';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { useSwiperSlider } from '../commons/hooks';

import SliderArrows from './SliderArrows';
import SliderArrowsSmall from './SliderArrowsSmall';

type SliderArrowsProps = PropsWithChildren<{
  breakpoints?: {
    [x: number]: {
      slidesPerView: number;
      spaceBetween: number;
    };
  };
  autoplay?: {
    delay: number;
    disableOnInteraction: boolean;
  };
  loop?: boolean;
  hideArrow?: boolean;
  smallArrow?: boolean;
  speed?: number;
  addShadow?: boolean;
  paginationDots?: boolean;
  lazyLoad?: boolean;
  className?: string;
  arrowClassName?: string;
}>;

export default function Slider(props: SliderArrowsProps) {
  const {
    breakpoints,
    loop,
    speed,
    autoplay,
    hideArrow,
    smallArrow,
    addShadow,
    paginationDots,
    lazyLoad,
    className = '',
    arrowClassName = '',
  } = props;
  const { setSwiper, prevRef, nextRef } = useSwiperSlider();
  const containerRef = useRef<HTMLDivElement>(null);
  const [startX, setStartX] = useState<number | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      if (!startX) return;
      const deltaX = startX - e.touches[0].pageX;

      if (Math.abs(deltaX) > 10 && e.cancelable) e.preventDefault();
    };

    const handleTouchStart = (e: TouchEvent) => {
      setStartX(e.touches[0].pageX);
    };

    const handleTouchEnd = () => {
      setStartX(null);
    };

    const container = containerRef.current;

    if (container) {
      container.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
      container.addEventListener('touchstart', handleTouchStart);
      container.addEventListener('touchend', handleTouchEnd);

      return () => {
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [startX]);

  // Custom pagination
  const pagination = {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet(index: number, classname: string) {
      return `<span class="${classname}"></span>`;
    },
  };

  const modules = [Navigation];

  if (paginationDots) modules.push(Pagination);
  if (autoplay) modules.push(Autoplay);

  const handleNavigationArrows = (swiperObj: any) => {
    if (prevRef.current !== undefined) {
      prevRef.current.classList.remove('hidden');
      nextRef.current.classList.remove('hidden');
      if (swiperObj.isBeginning) prevRef.current.classList.add('hidden');

      if (swiperObj.isEnd) nextRef.current.classList.add('hidden');
    }
  };

  return (
    <div ref={containerRef}>
      <Swiper
        className={cn(className, {
          '!pb-12': paginationDots,
        })}
        modules={modules}
        lazyPreloadPrevNext={lazyLoad ? 1 : undefined}
        pagination={paginationDots ? pagination : undefined}
        updateOnWindowResize
        observer
        onSwiper={setSwiper}
        onInit={(swiper) => handleNavigationArrows(swiper)}
        onResize={(swiper) => handleNavigationArrows(swiper)}
        // Update End + Beginning on Init
        onSlideChangeTransitionEnd={(swiper) => handleNavigationArrows(swiper)}
        onObserverUpdate={(swiper) => {
          handleNavigationArrows(swiper);
          swiper.slideTo(0, 0);
        }}
        breakpoints={breakpoints}
        autoplay={autoplay}
        loop={loop || undefined}
        speed={speed || 300}
      >
        {React.Children.map(props.children, (child, i) => {
          // To avoid empty child still occupying a SwiperSlide slot
          if (child) {
            return (
              <SwiperSlide
                style={{
                  paddingBottom: `${addShadow ? '2.2rem' : '0'}`,
                }}
                key={`slider-key-${child}-${i}`}
              >
                {child}
              </SwiperSlide>
            );
          }

          return <></>;
        })}
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        {paginationDots && <div className="swiper-pagination"></div>}
      </Swiper>
      {!smallArrow && !hideArrow && (
        <SliderArrows
          prevRef={prevRef}
          nextRef={nextRef}
          className={arrowClassName}
        />
      )}
      {smallArrow && <SliderArrowsSmall prevRef={prevRef} nextRef={nextRef} />}
    </div>
  );
}
