import { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Breakpoints } from '@/settings/breakpoints';

/** @deprecated use tailwind instead */
export const useMediaQuery = (width: Breakpoints) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: any) => {
    setTargetReached(e.matches);
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);

    media?.addEventListener?.('change', updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) setTargetReached(true);

    return () => media?.removeEventListener?.('change', updateTarget);
  }, []);

  return targetReached;
};

export const useSwiperSlider = () => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const prevRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const nextRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (prevRef.current === undefined) return;
    if (!swiper || typeof swiper?.params?.navigation === 'boolean') return;
    const navigation = swiper.params?.navigation;

    if (navigation) {
      navigation.prevEl = prevRef.current;
      navigation.nextEl = nextRef.current;
    }
    swiper.navigation?.init();
    swiper.navigation?.update();
    if (swiper.isBeginning) prevRef.current.classList.add('hidden');

    if (swiper.isEnd) nextRef.current.classList.add('hidden');
  }, [swiper]);

  return { swiper, setSwiper, prevRef, nextRef };
};
