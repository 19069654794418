interface SliderArrowsSmallProps {
  prevRef: React.MutableRefObject<HTMLDivElement>;
  nextRef: React.MutableRefObject<HTMLDivElement>;
}

export default function SliderArrowsSmall(props: SliderArrowsSmallProps) {
  const { prevRef, nextRef } = props;
  const arrowCss =
    'lg:absolute top-1/2 z-20 w-0 lg:w-7 h-0 lg:h-7 bg-white hover:bg-gray-700 rounded-full border-0 lg:border lg:border-gray-700 transition-all duration-500 -translate-y-1/2 cursor-pointer hover:fill-white scale-100 flex items-center justify-center select-none';
  const arrowInnerCss = 'hidden w-2 lg:block';

  return (
    <>
      <div ref={prevRef} className={`-left-4 ${arrowCss}`}>
        <div className={arrowInnerCss}>
          <svg viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              transform="translate(0, 0) rotate(0) "
            />
          </svg>
        </div>
      </div>
      <div ref={nextRef} className={`-right-4 ${arrowCss}`}>
        <div className={arrowInnerCss}>
          <svg viewBox="0 0 100 100">
            <path
              d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
              transform="translate(100, 100) rotate(180) "
            />
          </svg>
        </div>
      </div>
    </>
  );
}
